
.App {
  text-align: center;
  padding: 50px;
}

.App-logo {
  animation: App-logo-spin 5s linear infinite;
  height: 100px;
}
.line-tg{
  margin: 0 auto;
  margin-bottom: 20px;
  width: 150px;
}
.full-name {
  color: rgb(105, 163, 250);

}
.Occupation-title {
  font-size: 1.2em;
  color: rgb(114, 143, 187);
}


.div-pg1 {
  background-color: black;
  /* min-height: 500px; */
  /* min-height: 100vh; */
}

.new-section-div{
  display:inline-block;
  /* background-color: #374457; */

  position: relative;
  width: 100%;
  background-color: white;
  margin-bottom: 2px;
  padding-bottom: 10px;
  /* overflow: visible; */
}
.floating-section-div{
  position: absolute;
  width: 25px;
  height: 40%;
  background-color: #374457;
  top: calc(50% - 20%);
  left: 0px;
}
.section-content-div{
  position: relative;
  display:inline-block; 
  left: 50px;
  width: calc(100% - 90px);
  height: 100%;
  background-color: white;
  z-index: 1000;
  padding: 20px;
  /* top: calc(50% - 20px); */
  
}
.section-title{
  color: #000000;
  clear: both;
  font-weight: 900;
  font-size: 1.1em;
  text-transform: uppercase;
  margin-bottom: 15px;

}
.skills-div{
  margin: 5px;
  position: relative;
  float: left;
  padding: 7px;
  background-color: #4F90CD;
  color: white;
  border-radius: 5px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1em;
}
.skills-div:hover{
  background-color: black ;
  color:#4F90CD;
}
.skills-small-div{
  margin: 3px;
  position: relative;
  float: left;
  padding: 4px;
  background-color: #4F90CD;
  color: white;
  border-radius: 2px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 0.5em;
}

.project-content{

  display:inline-block;
  display: table-cell;
  /* margin: 0 auto; */
  margin-right: 10px;
  margin-bottom: 5px;
  position: relative;
  color: black;
  float: left;
  /* background-color: #374457; */
  background-color: white;
  width:  150px;
  padding: 10px;
  border: 1px solid rgb(193, 193, 193);
  left: calc(50% - 150px - 20px);
  border-radius: 15px;

}
.project-content:hover{
  background-color: #4F90CD;
color:white;
}

.project-img{
  animation: App-logo-spin2 0.4s linear infinite;
  width: 65px;
  opacity: 0.2;
  clear: both;
  margin:  5px auto;
  margin-bottom: 10px;
}
.project-title{
  margin: 0 auto;
  clear: both;
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 5px;

}
.project-tech{
  position: relative;

}
.div-pg1-bg{
  /* background-image: url("/middle.png"); */
  /* width: 100%;
  height: 100%; */
}
.div2{
  /* background-color:red; */
  /* min-height: 500px; */
  /* min-height: 100vh; */
}
.lnk-div{
  margin-top: 10px;
}
.lnk-div a{
  color: rgb(142, 156, 169);
  text-decoration: none;
}
.contact-link a{
  text-decoration: none;
  padding: 5px;
}
.contact-link a:hover{
  text-decoration: none;
  background-color: #4F90CD;
  color: white;
}
.contact-link{
  font-size: 1.1em;
  
}
.contact-link:hover{
  
}
.copy-clipboard{
  width: 0.75em;
}
.momentTimer{
  color: rgb(94, 184, 252);
}
@keyframes App-logo-spin {
  /* from { transform: scale(1); }
  to { transform: scale(2); } */
  0% { transform: scale(1);  }
  50% {  transform: scale(1.7); }
  100% {  transform: scale(1);}
}

@keyframes App-logo-spin2 {
  0% { transform: rotate(0deg); }
  50% {  transform: rotate(5deg) }
  100% {  transform: rotate(0deg) }
}
/* parallax css: */

.parallax-container {
  position: relative;
  /* overflow: hidden; */
  /* height: 500px; */
}

.parallax-container .parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/* .parallax-container .parallax img {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
} */
@media only screen and (max-width : 520px) {
/* smalest view */
.project-content{
width: 250px;
clear: both;
left: calc(50% - 125px - 20px);
}
}
@media only screen and (min-width : 536px) {
  /* smalest view */
  .project-content{
  width: 190px;
  float: left;
  left: calc(50% - 190px - 20px);
  }
  }
@media only screen and (min-width : 670px) {
  /* smalest view */
  .project-content{
  width: 250px;
  float: left;
  left: calc(33% - 125px - 40px);
  }
  }

  @media only screen and (min-width : 936px) {
    /* smalest view */
    .project-content{
    width: 250px;
    float: left;
    left: calc(23% - 125px - 40px);
    }
    }

    @media only screen and (min-width : 1178px) {
      /* smalest view */
      .project-content{
      width: 250px;
      float: left;
      left: calc(15% - 125px - 40px);
      }
      }
    
@media only screen and (min-width : 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }
  #index-banner .section {
    top: 10%;
  }
  .Occupation-title {
    font-size: 1em;
  }
  .App-logo {
    height: 60px;
  }
}